import "./styles.css";
import "./spinner.css";
import React, { useState, useEffect } from "react";
import axios from "axios";

const SearchBox = ({ onSearch }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch(inputValue);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <button onClick={() => onSearch(inputValue)}>Search</button>
    </div>
  );
};

const StorymakeCard = ({ storymake, onGoBack }) => {
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect((storymakeId) => {
    fetchScenarios(storymake.id);
  }, []);

  const fetchScenarios = async (storymakeId) => {
    try {
      if (window.Telegram.WebApp.initDataUnsafe.user != null) {
        var userId = window.Telegram.WebApp.initDataUnsafe.user.id;
      } else userId = 0;

      const response = await axios.get(
        "https://storymaker-api.azurewebsites.net/webapp/scenarios/" +
        storymakeId +
        "/" +
        userId
      ); // Replace with your API endpoint

      setScenarios(response.data);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error("Error fetching scenarios:", error);
    }
  };

  const handleScenarioClick = async (scenario) => {
    console.log("ScenarioId clicked: ", scenario.id);

    try {
      if (window.Telegram.WebApp.initDataUnsafe.user != null) {
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
        const link = "https://t.me/FictionWOW_bot?start=retry_" + scenario.id;

        if (link != null) {
          window.Telegram.WebApp.openTelegramLink(link);
          window.Telegram.WebApp.close();
        }
      }
    } catch (error) {
      console.error("Error sending data after scenario clicked:", error);
    }
  };

  const status = (scenario) => {
    return (
      <>
        {scenario.status === "active" ? (
          <div className="scenario-header">
            <p>Осталось: {scenario.durationLeft}</p>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  };

  const release = (scenario) => {
    return (
      <>
        {scenario.toBeReleased !== null && scenario.toBeReleased !== "" ? (
          <div className="scenario-header">
            <p>{scenario.toBeReleased}</p>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  };

  const beginButton = (scenario) => {
    return (
      <>
        {scenario.toBeReleased === null || scenario.toBeReleased === "" ? (
          <button
            className="scenario-button"
            onClick={() => handleScenarioClick(scenario)}
          >
            <span role="img" aria-label="rocket emoji">
              {button_text(scenario)}
            </span>
          </button>
        ) : (
          <div />
        )}
      </>
    );
  };

  const button_text = (scenario) => {
    return scenario.status === "active" ? "Продолжить 👉" : "Начать 🚀";
  };

  const warning_box = (scenario) => {
    return (
      <>
        {scenario.status === "active" ? (
          <div className="scenario-warning">
            <p>
              Через <b>{scenario.leaseTimeout}</b> мы завершим вашу чат-сессию.
              Если это случится - не переживайте: позже вы сможете запустить
              сторимейк заново в любое удобное время.
            </p>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  };

  return (
    <div>
      {loading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          <div className="book-card">
            <div className="book-card-background">
              <div className="button-fixed" onClick={onGoBack}>
                <div className="arrow-icon" />
              </div>
            </div>
          </div>
          <div className="storymake-list-container">
            <p className="scenarios-header">
              Все тизеры по книге
              <br />"{storymake.title}"
              <a
                href={storymake.salesLink}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                &nbsp;&#10697;
              </a>
            </p>
            {storymake.toBeReleased === "" ||
              storymake.toBeReleased === null ? (
              <button
                className="button-runscenario"
                onClick={() => handleScenarioClick(scenarios[0])}
              >
                ► Запустить случайный тизер
              </button>
            ) : (
              <div />
            )}
            <ul className="scenario-list">
              {scenarios.map((scenario) => (
                <li key={scenario.id}>
                  <div className="scenario">
                    {status(scenario)}
                    {release(scenario)}
                    <div className="scenario-content">
                      <img
                        src={scenario.poster}
                        alt="Scenario Cover"
                        className="scenario-image"
                      />
                      <div className="scenario-title">{scenario.title}</div>
                      <div className="scenario-status">
                        <p>Продолжительность: {scenario.duration}</p>
                      </div>
                      <div className="scenario-description">
                        {scenario.description}
                      </div>
                      {warning_box(scenario)}
                    </div>
                  </div>
                  {beginButton(scenario)}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

const StorymakeList = ({ storymakes, onStorymakeClick }) => {
  const handleScenarioClick = async (scenarioId) => {
    console.log("ScenarioId clicked: ", scenarioId);

    try {
      if (window.Telegram.WebApp.initDataUnsafe.user != null) {
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
        const link = "https://t.me/FictionWOW_bot?start=retry_" + scenarioId;

        if (link != null) {
          window.Telegram.WebApp.openTelegramLink(link);
          window.Telegram.WebApp.close();
        }
      }
    } catch (error) {
      console.error("Error sending data after scenario clicked:", error);
    }
  };

  return (
    <div className="book-gallery">
      {storymakes.map((storymake) => (
        <div className="book-summary">
          <div className="book-cover-container">
            {storymake.toBeReleased === "" ||
              storymake.toBeReleased === null ? (
              <div className="available-header">
                <p>Доступен</p>
              </div>
            ) : (
              <div className="to-be-released-header">
                <p>{storymake.toBeReleased}</p>
              </div>
            )}
            <img
              src={storymake.cover}
              alt={storymake.title}
              className="book-cover"
              width="100%"
              onClick={() => onStorymakeClick(storymake)}
            />
            <div class="button-container">
              {storymake.toBeReleased === "" ||
                storymake.toBeReleased === null ? (
                <button
                  className="button-run"
                  key={storymake.id}
                  onClick={() => handleScenarioClick(storymake.startScenarioId)}
                >
                  ►
                </button>
              ) : (
                <div />
              )}
              {/* </a> */}
            </div>
          </div>
          <div className="book-details-container">
            <div className="book-header-container">
              <h3 className="book-title">{storymake.title}</h3>
              <h4 className="book-author">
                {storymake.author}, {storymake.year}
              </h4>
            </div>
            <button
              className="button-book-details"
              key={storymake.id}
              onClick={() => onStorymakeClick(storymake)}
            >
              Все тизеры
            </button>
            <div className="book-description">{storymake.shortSummary}</div>
            <div className="book-litres-ref">
              <a href={storymake.salesLink} target="_blank" rel="noreferrer">
                Книга на Литрес
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const App = () => {
  window.Telegram.WebApp.MainButton.setText("ЗАКРЫТЬ");
  //if (window.Telegram.WebApp.isExpanded == false)
  window.Telegram.WebApp.expand();

  const [searchValue, setSearchValue] = useState("");
  //const [selectedBook, setSelectedBook] = useState(null);
  const [storymakes, setStorymakes] = useState([]);
  const [selectedStorymake, setSelectedStorymake] = useState(null);
  const [loading, setLoading] = useState(true);

  const params = new URLSearchParams(window.location.pathname);

  useEffect(() => {
    fetchStorymakes();
  }, []);

  const fetchStorymakes = async () => {
    try {
      const response = await axios.get(
        "https://storymaker-api.azurewebsites.net/webapp/storymakes/5"
      ); // Replace with your API endpoint
      setStorymakes(response.data);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error("Error fetching storymakes:", error);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleStorymakeClick = (storymake) => {
    setSelectedStorymake(storymake);
  };

  const handleGoBack = () => {
    setSelectedStorymake(null);
  };

  // const filteredBooks = books.filter((book) =>
  //   book.title.toLowerCase().includes(searchValue.toLowerCase())
  // );

  const getUserName = () => {
    if (
      window.Telegram != null &&
      window.Telegram.WebApp != null &&
      window.Telegram.WebApp.initDataUnsafe != null &&
      window.Telegram.WebApp.initDataUnsafe.user != null
    )
      return window.Telegram.WebApp.initDataUnsafe.user.id;
    else return "";
  };

  const filteredStorymakes = storymakes.filter((storymake) =>
    storymake.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div>
      {loading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="container">
          {selectedStorymake ? (
            <StorymakeCard
              storymake={selectedStorymake}
              onGoBack={handleGoBack}
            />
          ) : (
            <div>
              {/* <SearchBox onSearch={handleSearch} /> */}
              {/* <p>{getUserName()}</p> */}
              <StorymakeList
                storymakes={filteredStorymakes}
                onStorymakeClick={handleStorymakeClick}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
